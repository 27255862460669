/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard React layouts
import Dashboard from "./layouts/dashboard";
import Tables from "./layouts/tables";
import Application from "./layouts/application";
import DataFields from "./layouts/Data Fields";
import MbbsDetails from "./components/MbbsDetails";
import OtherCourses from "./components/OtherCourses";
import LoginPage from "./components/login/LoginPage";
import DashboardLayout from "./examples/LayoutContainers/DashboardLayout";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React icons
import Shop from "./examples/Icons/Shop";
import Office from "./examples/Icons/Office";
import CustomerSupport from "./examples/Icons/CustomerSupport";
import CreditCard from "./examples/Icons/CreditCard";
import Document from "./examples/Icons/Document";
import SpaceShip from "./examples/Icons/SpaceShip";
import Settings from "./examples/Icons/Settings";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: <Dashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "MBBS Abroad",
    key: "mbbs-abroad",
    route: "/mbbs-abroad",
    icon: <Office size="12px" />,
    component: <Tables />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Other Courses",
    key: "other-courses",
    route: "/other-courses",
    icon: <CreditCard size="12px" />,
    component: <OtherCourses />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Applications",
    key: "applications",
    route: "/applications",
    icon: <Document size="12px" />,
    component: <Application />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Visa",
    key: "visa",
    route: "/visa",
    icon: <SpaceShip size="12px" />,
    component: <DashboardLayout><h1>Visa</h1></DashboardLayout>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Data Fields",
    key: "data-fields",
    route: "/data-fields",
    icon: <Settings size="12px" />,
    component: <DataFields />,
    noCollapse: true,
  },
  { type: "title", title: "Account Pages", key: "account-pages" },
  { type: "hidden", key: "mbbs-detail", route: "/mbbs/:courseId", component: <MbbsDetails /> },
  { type: "hidden", key: "login", route: "/login", component: <LoginPage /> },
  {
    type: "collapse",
    name: "User Data",
    key: "user-data",
    route: "/user-data",
    icon: <CustomerSupport size="12px" />,
    component: <DashboardLayout><h1>User Data</h1></DashboardLayout>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    route: "/settings",
    icon: <Icon>settings</Icon>,
    component: <DashboardLayout><h1>Settings</h1></DashboardLayout>,
    noCollapse: true,
  },
];

export default routes;
