import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import { TabView, TabPanel } from "primereact/tabview";
import IncompleteProfile from "./IncompleteProfile";

const TabHeader = (options) => (
	<div onClick={options.onClick} className={options.className} style={{ maxWidth: '230px', padding: '15px' }} >
		<span style={{ fontSize: '15px', whiteSpace: 'break-spaces' }} >{options.titleElement}</span>
	</div>);

const Application = () => {
	return (
		<DashboardLayout>
			<DashboardNavbar />
			<TabView >
				<TabPanel headerTemplate={TabHeader} header='Incomplete Profile' >
					<IncompleteProfile />
				</TabPanel>
				<TabPanel headerTemplate={TabHeader} header='Document Upload Pending' >Document Upload Pending</TabPanel>
				<TabPanel headerTemplate={TabHeader} header='Conditional Offer' >Conditional Offer</TabPanel>
				<TabPanel headerTemplate={TabHeader} header='Unconditional Offer' >
					<IncompleteProfile />
				</TabPanel>
				<TabPanel headerTemplate={TabHeader} header='Pending From University' >
					Pending From University
				</TabPanel>
				<TabPanel headerTemplate={TabHeader} header='College Awaiting Document' >College Awaiting Document</TabPanel>
				<TabPanel headerTemplate={TabHeader} header='Offer Accepted' >
					<IncompleteProfile />
				</TabPanel>
				<TabPanel headerTemplate={TabHeader} header='Visa' >Visa</TabPanel>

			</TabView>
		</DashboardLayout>
	)
}
export default Application