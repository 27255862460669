/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftButton from "../../components/SoftButton";
import SoftAvatar from "../../components/SoftAvatar";

// Soft UI Dashboard React examples
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
// import Footer from "../../examples/Footer";

// Soft UI Dashboard React base styles
import typography from "../../assets/theme/base/typography";
// @mui material components
import Icon from "@mui/material/Icon";

import { Button, Input, Space, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Modal } from "react-bootstrap";
import { base_url } from "../../actions/baseUrls";
import { useDispatch } from "react-redux";
import { addNotification } from "../../actions/notifications";

function Tables() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const searchInput = useRef(null);

  const [dataSource, setDataSource] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [collegeDetails, setCollegeDetails] = useState({});

  const showModal = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);

  const handleSearch = (selectedKeys, confirm, dataIndex) => confirm();

  const handleReset = clearFilters => clearFilters();

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={e => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
          fontSize: "1rem",
        }}
      />
    ),
    onFilter: (value, record) => {
      return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const fetchAllColleges = () => {
    axios
      .get(`${base_url}/mbbs/college/all`)
      .then(res => setDataSource(res.data.mbbsColleges))
      .catch(err => console.log(err));
  };

  const handleDelete = () => {
    const token = localStorage.getItem('token');
    axios
      .delete(`${base_url}/mbbs/${collegeDetails.dbId}`, {
        headers: {
          'token': token
        }
      })
      .then(res => {
        if (res.data.detail === 'College details deleted successfully.') {
          fetchAllColleges();
          setIsModalOpen(false);
          dispatch(
            addNotification({
              message: "College deleted successfully",
              type: "success",
            })
          );
        }
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    fetchAllColleges();
  }, []);

  const { size, fontWeightBold } = typography;

  const columns = [
    {
      title: <SoftBox
        key={uuidv4()}
        component="th"
        width={"auto"}
        pt={1.5}
        pb={1.25}
        pl={3}
        pr={3}
        textAlign={'left'}
        fontSize={size.md}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={1}
      >
        {'College Name'}
      </SoftBox>,
      dataIndex: "collegeName",
      key: "name",
      width: 300,
      sorter: {
        compare: (a, b) => {
          if (!a || !b) return 0;
          if (a.collegeName.toLowerCase() < b.collegeName.toLowerCase()) return -1;
          if (b.collegeName.toLowerCase() < a.collegeName.toLowerCase()) return 1;
          return 0;
        },
      },
      render: (collegeName, data) => {
        return (
          <SoftBox display="flex" alignItems="center" py={0.5} px={1} maxWidth='400px' >
            <SoftBox mr={2}>
              <SoftAvatar src={data.logoImg} name={collegeName} variant="rounded" size="sm" />
            </SoftBox>
            <SoftTypography variant="button" fontWeight="medium" sx={{ width: "max-content" }}>
              {collegeName}
            </SoftTypography>
          </SoftBox>
        );
      },
      ...getColumnSearchProps("collegeName"),
    },
    {
      title: <SoftBox
        key={uuidv4()}
        component="th"
        width={"auto"}
        pt={1.5}
        pb={1.25}
        pl={3}
        pr={3}
        textAlign={'left'}
        fontSize={size.md}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={1}
      >
        {'Country'}
      </SoftBox>,
      dataIndex: "country",
      key: "country",
      width: 200,
      sorter: {
        compare: (a, b) => {
          if (!a || !b) return 0;
          if (a.country.toLowerCase() < b.country.toLowerCase()) return -1;
          if (b.country.toLowerCase() < a.country.toLowerCase()) return 1;
          return 0;
        },
      },
      render: (country, data) => {
        return (
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="caption" fontWeight="medium" color="text">
              {country}
            </SoftTypography>
            <SoftTypography variant="caption" color="secondary">
              {data.city}
            </SoftTypography>
          </SoftBox>
        );
      },
      ...getColumnSearchProps("country"),
    },
    {
      title: <SoftBox
        key={uuidv4()}
        component="th"
        width={"auto"}
        pt={1.5}
        pb={1.25}
        pl={3}
        pr={3}
        textAlign={'left'}
        fontSize={size.md}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={1}
      >
        {'College ID'}
      </SoftBox>,
      dataIndex: "collegeId",
      key: "collegeId",
      width: 200,
      sorter: {
        compare: (a, b) => {
          if (!a || !b) return 0;
          if (a.collegeId.toLowerCase() < b.collegeId.toLowerCase()) return -1;
          if (b.collegeId.toLowerCase() < a.collegeId.toLowerCase()) return 1;
          return 0;
        },
      },
      render: (collegeId) => (<SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {collegeId}
      </SoftTypography>),
      ...getColumnSearchProps("collegeId"),
    },
    {
      title: <SoftBox
        key={uuidv4()}
        component="th"
        width={"auto"}
        pt={1.5}
        pb={1.25}
        pl={3}
        pr={3}
        textAlign={'left'}
        fontSize={size.md}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={1}
      >
        {'Course ID'}
      </SoftBox>,
      dataIndex: "courseId",
      key: "courseId",
      width: 200,
      sorter: {
        compare: (a, b) => {
          if (!a || !b) return 0;
          if (a.courseId.toLowerCase() < b.courseId.toLowerCase()) return -1;
          if (b.courseId.toLowerCase() < a.courseId.toLowerCase()) return 1;
          return 0;
        },
      },
      render: (courseId) => (<SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {courseId}
      </SoftTypography>),
      ...getColumnSearchProps("courseId"),
    },
    {
      title: <SoftBox
        key={uuidv4()}
        component="th"
        width={"auto"}
        pt={1.5}
        pb={1.25}
        pl={3}
        pr={3}
        textAlign={'left'}
        fontSize={size.md}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={1}
      >
        {'Actions'}
      </SoftBox>,
      key: "Actions",
      dataIndex: "action",
      width: 200,
      render: (_, data) => {
        return (
          <SoftBox
            display="flex"
            alignItems="center"
            mt={{ xs: 2, sm: 0 }}
            ml={{ xs: -1.5, sm: 0 }}
          >
            <SoftBox mr={1}>
              <SoftButton variant="text" color="error" onClick={() => navigate(`/mbbs/${data?.courseId}`)} >
                <Icon>edit</Icon>&nbsp;edit
              </SoftButton>
            </SoftBox>
            <SoftButton variant="text" color="dark" onClick={() => {
              setCollegeDetails({
                dbId: data?.dbId,
                courseId: data?.courseId,
              });
              showModal();
            }}>
              <Icon>delete</Icon>&nbsp;delete
            </SoftButton>
          </SoftBox>
        );
      },
    },
  ];

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox py={3}>
          <SoftBox mb={3}>
            <Card>
              <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
                <SoftTypography variant="h6">College Details</SoftTypography>
                <SoftButton variant="gradient" color="dark" onClick={() => navigate("/mbbs/create")} >
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                  &nbsp;add new college
                </SoftButton>
              </SoftBox>
              <SoftBox
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
                pr={1}
              >
                <Table
                  scroll={{ x: true }}
                  dataSource={dataSource}
                  columns={columns}
                  rowKey={dataSource => dataSource?.dbId}
                  pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
                />
              </SoftBox>
            </Card>
          </SoftBox>
        </SoftBox>
      </DashboardLayout>
      <Modal
        show={isModalOpen}
        onHide={handleClose}
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this college?</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            type="button"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className="btn btn-danger ms-3 align-self-center fs-7 fw-500"
            type="button"
            onClick={() => handleDelete()}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Tables;
