import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";

import { TabView, TabPanel } from "primereact/tabview";
import All from "./All";
import CollegeFilters from "./CollegeFilters";
import CollegeOptions from "./CollegeOptions";
import Documents from "./Documents";
import MBBSFilters from "./MBBSFilters";
import MBBSOptions from "./MBBSOptions";

const TabHeader = (options) => (
    <div onClick={options.onClick} className={options.className} >
        <span>{options.titleElement}</span>
    </div>);

const DataFields = () => {
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <TabView>
                <TabPanel headerTemplate={TabHeader} header='All' >
                    <All />
                </TabPanel>
                <TabPanel headerTemplate={TabHeader} header='College Filters' >
                    <CollegeFilters />
                </TabPanel>
                <TabPanel headerTemplate={TabHeader} header='College Options' >
                    <CollegeOptions />
                </TabPanel>
                <TabPanel headerTemplate={TabHeader} header='Documents' >
                    <Documents />
                </TabPanel>
                <TabPanel headerTemplate={TabHeader} header='MBBS Filters' >
                    <MBBSFilters />
                </TabPanel>
                <TabPanel headerTemplate={TabHeader} header='MBBS Options' >
                    <MBBSOptions />
                </TabPanel>

            </TabView>
        </DashboardLayout>
    )
}
export default DataFields;