
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogin } from "../../actions/user";
import { getUserDetails } from "../../reducers/user";
import PageLayout from "../../examples/LayoutContainers/PageLayout";
import LoginForm from "./LoginForm";
import { useEffect } from 'react';
import curved9 from "../../assets/images/curved-images/curved-6.jpg";
import Grid from "@mui/material/Grid";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";

const LoginPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const user = useSelector(getUserDetails);

	const handleSubmit = formData => {
		dispatch(userLogin({ emailId: formData.username, password: formData.password }));
	};

	const token = localStorage.getItem('token');

	useEffect(() => {
		if (user?.isLoggedIn && !!token) {
			navigate('/dashboard');
		}
	}, [token, user, navigate]);

	return (
		<PageLayout background="white">

			<Grid
				container
				justifyContent="center"
				sx={{
					minHeight: "90vh",
					margin: 0,
				}}
			>
				<Grid item xs={11} sm={8} md={5} xl={3}>
					<SoftBox mt={20} >
						<SoftBox pt={3} px={3}>
							<SoftBox mb={1}>
								<SoftTypography variant="h3" fontWeight="bold" color="info" textGradient>
									{'MCP Admin Panel'}
								</SoftTypography>
							</SoftBox>
							<SoftTypography variant="body2" fontWeight="regular" color="text">
								{'Enter your email and password to sign in'}
							</SoftTypography>
						</SoftBox>

						<SoftBox p={3}>
							<LoginForm onSubmit={handleSubmit} />
						</SoftBox>

					</SoftBox>
				</Grid>
				<Grid item xs={12} md={5}>
					<SoftBox
						height="100%"
						display={{ xs: "none", md: "block" }}
						position="relative"
						right={{ md: "-12rem", xl: "-16rem" }}
						mr={-16}
						sx={{
							transform: "skewX(-10deg)",
							overflow: "hidden",
							borderBottomLeftRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
						}}
					>
						<SoftBox
							ml={-8}
							height="100%"
							sx={{
								backgroundImage: `url(${curved9})`,
								backgroundSize: "cover",
								transform: "skewX(10deg)",
							}}
						/>
					</SoftBox>
				</Grid>
			</Grid>

		</PageLayout>
	);
};

export default LoginPage;
