/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "../../components/SoftBox";

// Soft UI Dashboard React examples
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";


// Soft UI Dashboard React base styles

// Dashboard layout components
import BuildByDevelopers from "../../layouts/dashboard/components/BuildByDevelopers";

function Dashboard() {

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <BuildByDevelopers title={'MBBS Abroad'} url={'/mbbs-abroad'} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <BuildByDevelopers title={'Other Courses'} url='/other-courses' />
            </Grid>
            <Grid item xs={12} lg={4}>
              <BuildByDevelopers title={'Data Fields'} url={'/data-fields'} />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <BuildByDevelopers title={'Applications'} url={'/applications'} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <BuildByDevelopers title={'Visa'} url='/visa' />
            </Grid>
            <Grid item xs={12} lg={4}>
              <BuildByDevelopers title={'User Data'} url='/user-data' />
            </Grid>
          </Grid>
        </SoftBox>

      </SoftBox>
    </DashboardLayout>
  );
}

export default Dashboard;
