import { Grid } from "@mui/material";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import { useState } from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';

const labels = [
	{ key: 'basicDetails', value: 'Basic Details' },
	{ key: 'educationAndWorkDetails', value: 'Education And Work Details' },
	{ key: 'parentDetails', value: 'Parent Details' },
	{ key: 'studyAbroadDetails', value: 'Study Abroad Details' },
];

const getVariant = (percent) => {
	if (percent < 26) return 'danger';
	if (percent < 51) return 'info';
	if (percent < 76) return 'warning';
	if (percent < 101) return 'success';
}

const Details = ({ title, value }) => (
	<SoftBox key={title} display="flex" py={1} pr={2} >
		<SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
			{title}: &nbsp;
		</SoftTypography>
		<SoftTypography variant="button" fontWeight="regular" color="text">
			&nbsp;{value === null ? '-' : value}
		</SoftTypography>
	</SoftBox>
);

const UserDetails = ({ userDetails }) => {
	const { basicDetails, educationAndWorkDetails, parentDetails, status, studyAbroadDetails } = userDetails;

	const [activeLabel, setActiveLabel] = useState('basicDetails');

	return (
		<Grid container spacing={2}>
			<Grid item xs={4} md={3} xl={3}>
				<SoftBox height="100%" pb={'1rem'} display="flex" flexDirection='column' alignItems="center" justifyContent='space-between'>
					<SoftBox display="flex" flexDirection='column' alignItems="center" justifyContent='center'>
						{labels.map((label) => (
							<SoftBox
								variant={activeLabel === label.key ? "contained" : "gradient"}
								bgColor={activeLabel === label.key ? "info" : "white"}
								color={activeLabel === label.key ? "white" : "dark"}
								width="17rem"
								height="3rem"
								borderRadius="md"
								display="flex"
								justifyContent="center"
								alignItems="center"
								shadow="md"
								textTransform='capitalize'
								mb={2}
								style={{ cursor: 'pointer' }}
								onClick={() => setActiveLabel(label.key)}
							>{label.value}</SoftBox>
						))}
					</SoftBox>
					<SoftBox width="17rem" >
						<SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
							Completion Progress
						</SoftTypography>
						<ProgressBar variant={getVariant(userDetails?.basicDetails?.profileCompletePercent || 0)}
							striped now={userDetails?.basicDetails?.profileCompletePercent || 0}
							label={`${userDetails?.basicDetails?.profileCompletePercent || 0}%`} />
					</SoftBox>
				</SoftBox>
			</Grid>

			<Grid item xs={8} md={9} xl={9}>
				{activeLabel === 'basicDetails' && <>
					<SoftTypography color="info" >Basic Details</SoftTypography>
					<Grid container justifyContent="space-around">
						<Grid items>
							<Details title='Name' value={basicDetails.firstName + ' ' + basicDetails.lastName} />
							<Details title='Email' value={basicDetails.email} />
							<Details title='Gender' value={basicDetails.gender} />
							<Details title='Date of Birth' value={basicDetails.dob} />
							<Details title='country Of Residence' value={basicDetails.countryOfResidence} />
							<Details title='family Occupation' value={basicDetails.familyOccupation} />
							<Details title='marital Status' value={basicDetails.maritalStatus} />
							<Details title='nationality' value={basicDetails.nationality} />
						</Grid>
						<Grid items>
							<Details title='country Of Residence' value={basicDetails.countryOfResidence} />
							<Details title='address' value={basicDetails.address.address} />
							<Details title='city' value={basicDetails.address.city} />
							<Details title='state' value={basicDetails.address.state} />
							<Details title='zipcode' value={basicDetails.address.zipcode} />
							<Details title='mobile' value={'+' + basicDetails.mobile.code + ' ' + basicDetails.mobile.mobile} />
							<Details title='preferred Mode Of Reach' value={basicDetails.preferredModeOfReach[1]} />
							<Details title='preferred Time Of Reach' value={basicDetails.preferredTimeToReach[1]} />
						</Grid>
					</Grid>
				</>}
				{activeLabel === 'educationAndWorkDetails' &&
					<Grid container justifyContent="space-around">
						<Grid items>
							<h3>Education Details</h3>
							<Grid container justifyContent="space-around">
								{educationAndWorkDetails.educationDetails.map((e) => (
									<Grid items>
										<SoftTypography color="info" >{e.educationLevel}</SoftTypography>
										<Details title='university' value={e.university} />
										<Details title='rank' value={e.rank} />
										<Details title='language Of Instruction' value={e.languageOfInstruction} />
										<Details title='grade System' value={e.gradeSystem} />
										<Details title='field Of Study' value={e.fieldOfStudy} />
										<Details title='education Status' value={e.educationStatus} />
										<Details title='course Type' value={e.courseType} />
										<Details title='course Name' value={e.courseName} />
										<Details title='country Of Education' value={e.countryOfEducation} />
										<Details title='achieved Marks' value={e.achievedMarks} />
										<Details title='start Date' value={e.startDate ? e.startDate?.month + ' ' + e.startDate?.year : null} />
										<Details title='end Date' value={e.endDate ? e.endDate?.month + ' ' + e.endDate?.year : null} />
									</Grid>
								))}
							</Grid>
						</Grid>
						<Grid items>
							<h3>Work Details</h3>
							{educationAndWorkDetails.workSummary.map((w) => (
								<>
									<SoftTypography color="info" >{w.companyName}</SoftTypography>
									<Details title='current Company' value={w.currentCompany ? 'Yes' : 'No'} />
									<Details title='job Type' value={w.jobType} />
									<Details title='job Description' value={w.jobDescription} />
									<Details title='start Date' value={w.startDate ? w.startDate?.month + ' ' + w.startDate?.year : null} />
									<Details title='end Date' value={w.endDate ? w.endDate?.month + ' ' + w.endDate?.year : null} />
								</>
							))}
						</Grid>
					</Grid>}
				{activeLabel === 'parentDetails' &&
					<Grid container justifyContent="space-around">
						<Grid items>
							<SoftTypography color="info" >Father Details</SoftTypography>
							<Details title='name' value={parentDetails.fatherDetails.name} />
							<Details title='email' value={parentDetails.fatherDetails.email} />
							<Details title='occupation' value={parentDetails.fatherDetails.occupation} />
							<Details title='phone Number' value={parentDetails.fatherDetails.phoneNumber} />
						</Grid>
						<Grid items>
							<SoftTypography color="primary" >Mother Details</SoftTypography>
							<Details title='name' value={parentDetails.motherDetails.name} />
							<Details title='email' value={parentDetails.motherDetails.email} />
							<Details title='occupation' value={parentDetails.motherDetails.occupation} />
							<Details title='phone Number' value={parentDetails.motherDetails.phoneNumber} />
						</Grid>
					</Grid>}

				{activeLabel === 'studyAbroadDetails' &&
					<Grid container justifyContent="space-around" >
						<Grid items xs={6} sm={6} md={6} >
							<SoftTypography color="info" >MBBS Abroad</SoftTypography>
							<Details title='country Of Issue' value={studyAbroadDetails.mbbsAbroad.countryOfIssue} />
							<Details title='annual Budget' value={studyAbroadDetails.mbbsAbroad.annualBudget} />
							<Details title='expiry Month Year' value={studyAbroadDetails.mbbsAbroad.expiryMonthYear} />
							<Details title='gave Neet' value={studyAbroadDetails.mbbsAbroad.gaveNeet ? 'Yes' : 'No'} />
							<Details title='issued Month Year' value={studyAbroadDetails.mbbsAbroad.issuedMonthYear} />
							<Details title='neet Score' value={studyAbroadDetails.mbbsAbroad.neetScore} />
							<Details title='passport Number' value={studyAbroadDetails.mbbsAbroad.passportNumber} />
							<Details title='short Listed Universities' value={studyAbroadDetails.mbbsAbroad.shortListedUniversities ? 'Yes' : 'No'} />
							<Details title='start College Intake' value={studyAbroadDetails.mbbsAbroad.startCollegeIntake} />
							<Details title='start College Year' value={studyAbroadDetails.mbbsAbroad.startCollegeYear} />
							<Details title='valid Passport' value={studyAbroadDetails.mbbsAbroad.validPassport ? 'Yes' : 'No'} />
							<Details title='preferred Country' value={studyAbroadDetails.mbbsAbroad.preferredCountry.toString()} />
							<Details title='objectives' value={studyAbroadDetails.mbbsAbroad.objectives.toString()} />
						</Grid>
						<Grid items xs={6} sm={6} md={6} >
							<SoftTypography color="info" >Other Courses</SoftTypography>
							<Details title='annual Budget' value={studyAbroadDetails.otherCourses.annualBudget} />
							<Details title='objectives' value={studyAbroadDetails.otherCourses.objectives.toString()} />
							<Details title='applyCourse' value={studyAbroadDetails.otherCourses.applyCourse} />
							<Details title='preferred Country' value={studyAbroadDetails.otherCourses.preferredCountry.toString()} />
							<Details title='selected Specializations' value={studyAbroadDetails.otherCourses.selectedSpecializations.toString()} />
							<Details title='short Listed Universities' value={studyAbroadDetails.otherCourses.shortListedUniversities ? 'Yes' : 'No'} />
							<Details title='start College Intake' value={studyAbroadDetails.otherCourses.startCollegeIntake} />
							<Details title='start College Year' value={studyAbroadDetails.otherCourses.startCollegeYear} />
							<Details title='target CourseLevel' value={studyAbroadDetails.otherCourses.targetCourseLevel} />
						</Grid>
					</Grid>}
			</Grid>
		</Grid>
	)
}
export default UserDetails