/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";

// Images
import Books from "../../../../assets/images/books.jpg";
import Visa from "../../../../assets/images/visa.jpg";
import Stethoscope from "../../../../assets/images/stethoscope.jpg";
import Application from "../../../../assets/images/application.jpg";
import DataFields from "../../../../assets/images/data-fields.jpg";
import UserData from "../../../../assets/images/user-data.jpg";
import React from "react";
import { useNavigate } from "react-router-dom";

const images = {
  'MBBS Abroad': Stethoscope,
  'Other Courses': Books,
  'Applications': Application,
  'Visa': Visa,
  'Data Fields': DataFields,
  'User Data': UserData,
};

function BuildByDevelopers({ title, url }) {
  const navigate = useNavigate();
  return (
    <Card>
      <SoftBox p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={7}>
            <SoftBox display="flex" flexDirection="column" height="100%">
              <SoftBox pt={1} mb={3}>
              </SoftBox>
              <SoftTypography variant="h3" fontWeight="bold" gutterBottom>
                {title}
              </SoftTypography>
              <SoftBox mb={6}>

              </SoftBox>
              <SoftTypography
                component="div"
                onClick={() => navigate(url)}
                variant="button"
                color="text"
                fontWeight="medium"
                sx={{
                  mt: "auto",
                  mr: "auto",
                  display: "inline-flex",
                  alignItems: "center",
                  cursor: "pointer",

                  "& .material-icons-round": {
                    fontSize: "1.125rem",
                    transform: `translate(2px, -0.5px)`,
                    transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                  },

                  "&:hover .material-icons-round, &:focus  .material-icons-round": {
                    transform: `translate(6px, -0.5px)`,
                  },
                }}
              >
                Go to
                <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} lg={5} sx={{ position: "relative", ml: "auto" }}>

            <SoftBox component="img" src={images[title]}
              width="100%" height="150px" borderRadius="lg" />

          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default BuildByDevelopers;
