import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Space, Spin } from "antd";
import axios from "axios";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";

import SoftBox from "../../components/SoftBox";
import { base_url } from "../../actions/baseUrls";
import { addNotification } from "../../actions/notifications";

const labels = [
  { key: 'courseDurationYears', value: 'Course Duration Years' },
];

const fieldOptions = [
  {
    page: 'mbbsFilters',
    label: 'courseDurationYears'
  },
];

const MBBSFilters = () => {
  const dispatch = useDispatch();

  const [activeLabel, setActiveLabel] = useState(labels[0].key);
  const [data, setData] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios.post(`${base_url}/data/field-options`, {
      fieldOptions
    }).then((res) => setData(res.data.mbbsFilters));
  };

  const handleAdd = () => {
    const options = [...data[activeLabel].options];
    options.push({ label: '', value: '' });
    const newData = {
      ...data,
      [activeLabel]: {
        ...data[activeLabel],
        options
      }
    };
    setData(newData);
  };

  const handleChange = (e, i) => {
    const options = [...data[activeLabel].options];
    options[i][e.target.name] = e.target.value;
    options[i]['value'] = e.target.value;
    const newData = {
      ...data,
      [activeLabel]: {
        ...data[activeLabel],
        options
      }
    };
    setData(newData);
  };

  const handleRemove = (i) => {
    const options = [...data[activeLabel].options];
    options.splice(i, 1);
    const newData = {
      ...data,
      [activeLabel]: {
        ...data[activeLabel],
        options
      }
    };
    setData(newData);
  };

  const handleSave = () => {
    const body = data[activeLabel];
    body['page'] = 'mbbsFilters';
    body['label'] = activeLabel;
    body.options.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));

    axios.post(`${base_url}/data/field-options/save`, body, {
      headers: {
        'accept': 'application/json',
        'token': localStorage.getItem('token')
      }
    }).then((res) => {
      if (res.data.success) {
        fetchData();
        dispatch(
          addNotification({
            message: `${labels[0].value} updated successfully`,
            type: "success",
          })
        );
      }
    }).catch(() => dispatch(
      addNotification({
        message: `Some error occurred. Please try again.`,
        type: "failure",
      })
    ));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={3} md={2} xl={2}>
        <SoftBox display="flex" flexDirection='column' alignItems="center" justifyContent='center'>
          {labels.map((label) => (
            <SoftBox
              variant={activeLabel === label.key ? "contained" : "gradient"}
              bgColor={activeLabel === label.key ? "info" : "white"}
              color={activeLabel === label.key ? "white" : "dark"}
              width="12rem"
              height="3rem"
              borderRadius="md"
              display="flex"
              justifyContent="center"
              alignItems="center"
              shadow="md"
              textTransform='capitalize'
              mb={2}
              style={{ cursor: 'pointer' }}
              onClick={() => setActiveLabel(label.key)}
            >{label.value}</SoftBox>
          ))}
        </SoftBox>
      </Grid>
      <Grid item xs={9} md={10} xl={10}>
        {data && data[activeLabel] ?
          <>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={handleAdd}
                label={`Add ${labels[0].value} `}
                style={{ backgroundColor: '#0d6efd', color: 'white', height: '40px' }}
                icon='pi pi-plus' iconPos="right"
              />
            </div>
            <form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {data[activeLabel].options?.map((body, index) => {
                return (
                  <Row key={index} className="mt-3" style={{ width: '90%' }} >
                    <Col>
                      <span className="p-float-label">
                        <InputText
                          id={`label-${index}`}
                          style={{ width: "100%" }}
                          placeholder="Label"
                          name="label"
                          value={body?.label}
                          onChange={e => handleChange(e, index)}
                        />
                        <label htmlFor={`label-${index}`} className="d-block form-label">
                          Label
                        </label>
                      </span>
                    </Col>
                    {/* <Col>
                      <span className="p-float-label">
                        <InputText
                          id={`value-${index}`}
                          style={{ width: "100%" }}
                          placeholder="Value"
                          name="value"
                          value={body?.value}
                          onChange={e => handleChange(e, index)}
                        />
                        <label htmlFor={`value-${index}`} className="d-block form-label">
                          Value
                        </label>
                      </span>
                    </Col> */}
                    <Col>
                      <Button
                        onClick={() => handleRemove(index)}
                        label='Remove'
                        style={{ backgroundColor: 'red', color: 'white' }}
                        icon='pi pi-times' iconPos="right"
                      />
                    </Col>
                  </Row>
                );
              })}
            </form>
            <Row className="mt-3" >
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                <Button style={{ width: '160px' }}
                  label='Save Details' icon='pi pi-save' iconPos="right"
                  onClick={() => handleSave()} />
              </div>
            </Row>
          </>
          : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "100%" }}
            >
              <Space size="middle">
                <Spin tip="Loading..." size="large" />
              </Space>
            </div>)}
      </Grid>
    </Grid>
  )
}
export default MBBSFilters;