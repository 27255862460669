import { InputText } from "primereact/inputtext";

export const ReduxFormTextField = ({
	input,
	label,
	type = "text",
	maxLength = 200,
	placeholder,
	meta: { touched, error, warning },
	...inputProps
}) => {
	return (
		<div>
			<div className="d-flex flex-column">
				<span className="p-float-label">
					<InputText
						style={{ width: "100%" }}
						{...input}
						className="form-control"
						placeholder={placeholder}
						type={type}
						maxLength={maxLength}
						{...inputProps}
						required={true}
					/>
					<label className="d-block form-label" style={{ fontSize: '15px' }} >{label}</label>
				</span>

				{touched &&
					((error && (
						<span style={{ color: "red", marginLeft: "4px", fontSize: '13px' }}>{error}</span>
					)) ||
						(warning && <span>{warning}</span>))}
			</div>
		</div>
	);
};
