import { Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { base_url } from "../actions/baseUrls";
import SoftBox from "./SoftBox";
import SoftTypography from "./SoftTypography";
import SoftAvatar from "./SoftAvatar";
import typography from "../assets/theme/base/typography";
import { v4 as uuidv4 } from "uuid";

const OtherCourses = () => {
	const { size, fontWeightBold } = typography;
	const [dataSource, setDataSource] = useState([]);

	useEffect(() => {
		axios
			.get(`${base_url}/all-other-college`)
			.then(res => setDataSource(res.data.otherColleges))
			.catch(err => console.log(err));
	}, []);

	const columns = [
		{
			title: <SoftBox
				key={uuidv4()}
				component="th"
				width={"auto"}
				pt={1.5}
				pb={1.25}
				pl={3}
				pr={3}
				textAlign={'left'}
				fontSize={size.md}
				fontWeight={fontWeightBold}
				color="secondary"
				opacity={1}
			>
				{'College Name'}
			</SoftBox>,
			dataIndex: "name",
			key: "name",
			width: 300,
			sorter: {
				compare: (a, b) => {
					if (!a || !b) return 0;
					if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
					if (b.name.toLowerCase() < a.name.toLowerCase()) return 1;
					return 0;
				},
			},
			render: (collegeName, data) => {
				return (
					<SoftBox display="flex" alignItems="center" py={0.5} px={1}>
						<SoftBox mr={2}>
							<SoftAvatar src={data.logoImg} name={collegeName} variant="rounded" size="sm" />
						</SoftBox>
						<SoftTypography variant="button" fontWeight="medium" sx={{ width: "max-content" }}>
							{collegeName}
						</SoftTypography>
					</SoftBox>
				);
			},
		},
		{
			title: <SoftBox
				key={uuidv4()}
				component="th"
				width={"auto"}
				pt={1.5}
				pb={1.25}
				pl={3}
				pr={3}
				textAlign={'left'}
				fontSize={size.md}
				fontWeight={fontWeightBold}
				color="secondary"
				opacity={1}
			>
				{'City-Country'}
			</SoftBox>,
			dataIndex: "country",
			key: "country",
			width: 200,
			sorter: {
				compare: (a, b) => {
					if (!a || !b) return 0;
					if (a.country.toLowerCase() < b.country.toLowerCase()) return -1;
					if (b.country.toLowerCase() < a.country.toLowerCase()) return 1;
					return 0;
				},
			},
			render: (country, data) => {
				return (
					<SoftBox display="flex" flexDirection="column">
						<SoftTypography variant="caption" fontWeight="medium" color="text">
							{country}
						</SoftTypography>
						<SoftTypography variant="caption" color="secondary">
							{data.address.city}
						</SoftTypography>
					</SoftBox>
				);
			},
		},
		{
			title: <SoftBox
				key={uuidv4()}
				component="th"
				width={"auto"}
				pt={1.5}
				pb={1.25}
				pl={3}
				pr={3}
				textAlign={'left'}
				fontSize={size.md}
				fontWeight={fontWeightBold}
				color="secondary"
				opacity={1}
			>
				{'College Id'}
			</SoftBox>,
			dataIndex: "collegeId",
			key: "collegeId",
			width: 200,
			sorter: {
				compare: (a, b) => {
					if (!a || !b) return 0;
					if (a.collegeId.toLowerCase() < b.collegeId.toLowerCase()) return -1;
					if (b.collegeId.toLowerCase() < a.collegeId.toLowerCase()) return 1;
					return 0;
				},
			},
			render: (collegeId) => (<SoftTypography variant="caption" color="secondary" fontWeight="medium">
				{collegeId}
			</SoftTypography>),
		},
		{
			title: <SoftBox
				key={uuidv4()}
				component="th"
				width={"auto"}
				pt={1.5}
				pb={1.25}
				pl={3}
				pr={3}
				textAlign={'left'}
				fontSize={size.md}
				fontWeight={fontWeightBold}
				color="secondary"
				opacity={1}
			>
				{'Course Id'}
			</SoftBox>,
			dataIndex: "courseId",
			key: "courseId",
			width: 200,
			sorter: {
				compare: (a, b) => {
					if (!a || !b) return 0;
					if (a.courseId.toLowerCase() < b.courseId.toLowerCase()) return -1;
					if (b.courseId.toLowerCase() < a.courseId.toLowerCase()) return 1;
					return 0;
				},
			},
			render: (courseId) => (<SoftTypography variant="caption" color="secondary" fontWeight="medium">
				{courseId}
			</SoftTypography>),
		},

	];
	return (
		<>
			<div className="d-flex justify-content-center mt-5">
				<Table
					dataSource={dataSource}
					columns={columns}
					rowKey={dataSource => dataSource?.courseId}
					pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
				/>
			</div>
		</>
	);
};

export default OtherCourses;
