import { useState } from "react";
import { Grid } from "@mui/material";
import { Dropdown } from "primereact/dropdown";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { base_url } from "../../actions/baseUrls";
import './styles.css';

import Card from "./Card";
import SoftBox from "../../components/SoftBox";
import UserDetails from "./UserDetails";

const otherDetails = [
  { title: 'Applicant Name', value: 'Amit Pathak' },
  { title: 'Email', value: 'amitpathak@strnglab.org' },
  { title: 'Mobile', value: '+91 9876543210' },
  { title: 'Applied on', value: '23/20/2023' },
  { title: 'Last Updated on', value: '28/02/2023' },
];

const sortByOptions = [
  { title: 'Last Updated on', value: 'Last Updated on' },
  { title: 'Applied on', value: 'Applied on' },
  { title: 'User Name', value: 'User Name' },
  { title: 'User ID', value: 'User ID' },
  { title: 'College Name', value: 'College Name' },
];

const userId = 'FastAPIUser';

const IncompleteProfile = () => {
  const [sortBy, setSortBy] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({});

  const showModal = async () => {
    const { data: userData } = await axios.get(`${base_url}/admin/applications/step1/${userId}`, {
      headers: {
        'accept': 'application/json',
        'token': localStorage.getItem('token')
      }
    });

    setUserDetails(userData);
    setIsModalOpen(true);
  };

  const handleClose = () => setIsModalOpen(false);

  return (
    <>
      <SoftBox display="flex" alignItems="center" justifyContent='flex-end' mb={2}>
        <span className="p-float-label" >
          <Dropdown inputId="sortBy" value={sortBy} onChange={(e) => setSortBy(e.value)}
            options={sortByOptions} optionLabel="title" style={{ height: '35px' }} />
          <label htmlFor="sortBy">Sort By</label>
        </span>
      </SoftBox>
      <Grid container spacing={3} bgcolor={'transparent'} >
        <Grid item xs={12} md={6} xl={4}>
          <Card
            image={'https://www.dnmc.edu.bd/public/logo/logo.png'}
            title='MBBS in Russia'
            collegeName='Pathak Medical Institute'
            status='red'
            otherDetails={otherDetails}
            onClick={() => showModal()}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={4}>
          <Card
            image={'https://static.vecteezy.com/system/resources/previews/005/260/727/original/college-graduate-icon-free-vector.jpg'}
            title='MBBS in Bangladesh'
            collegeName='Monno Medical College & Hospital'
            status='green'
            otherDetails={otherDetails}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={4}>
          <Card
            image={'https://www.dnmc.edu.bd/public/logo/logo.png'}
            title='MBBS in Ukraine'
            collegeName="Sylhet Women'S Medical College"
            status='orange'
            otherDetails={otherDetails}
          />
        </Grid>

        <Grid item xs={12} md={6} xl={4}>
          <Card
            image={'https://static.vecteezy.com/system/resources/previews/005/260/727/original/college-graduate-icon-free-vector.jpg'}
            title='MBBS in Bangladesh'
            collegeName='Monno Medical College & Hospital'
            status='green'
            otherDetails={otherDetails}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={4}>
          <Card
            image={'https://www.dnmc.edu.bd/public/logo/logo.png'}
            title='MBBS in Russia'
            collegeName='Pathak Medical Institute'
            status='orange'
            otherDetails={otherDetails}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={4}>
          <Card
            image={'https://www.dnmc.edu.bd/public/logo/logo.png'}
            title='MBBS in Ukraine'
            collegeName="Sylhet Women'S Medical College"
            status='red'
            otherDetails={otherDetails}
          />
        </Grid>

      </Grid>
      <Modal
        show={isModalOpen}
        onHide={handleClose}
        keyboard={false}
        centered size="lg"
        dialogClassName="my-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title >User Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserDetails userDetails={userDetails} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default IncompleteProfile;