import { notification } from "antd";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { persistor, store } from "./store";
import { SoftUIControllerProvider } from "./context";
import { BrowserRouter } from "react-router-dom";

notification.config({
	placement: "topRight",
	top: 60,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Provider store={store}>
		<BrowserRouter>
			<PersistGate loading={null} persistor={persistor}>
				<SoftUIControllerProvider>
					<App />
				</SoftUIControllerProvider>
			</PersistGate>
		</BrowserRouter>
	</Provider>
);
