import { Space, Spin } from "antd";
import axios from "axios";
import { Chips } from "primereact/chips";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from 'primereact/inputnumber';

import { Button } from 'primereact/button';

import { MultiSelect } from "primereact/multiselect";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { base_url } from "../actions/baseUrls";
import { addNotification } from "../actions/notifications";
import { useSoftUIController, setMiniSidenav } from "../context";

import { TabView, TabPanel } from 'primereact/tabview';
import { Calendar } from 'primereact/calendar';
import DashboardNavbar from "../examples/Navbars/DashboardNavbar";

const htmlWithTable = ``;
const htmlWithTable2 = ``;
const htmlWithTable3 = ``;
const defaultFonts = [
	"Arial",
	"Comic Sans MS",
	"Courier New",
	"Impact",
	"Georgia",
	"Tahoma",
	"Trebuchet MS",
	"Verdana",
];
const sortedFontOptions = [
	"Logical",
	"Salesforce Sans",
	"Garamond",
	"Sans-Serif",
	"Serif",
	"Times New Roman",
	"Helvetica",
	'Montserrat',
	...defaultFonts,
].sort();

const editorOptions = {
	buttonList: [
		["undo", "redo"],
		["font", "fontSize"],
		["paragraphStyle", "blockquote"],
		["bold", "underline", "italic", "strike", "subscript", "superscript"],
		["fontColor", "hiliteColor"],
		["align", "list", "lineHeight"],
		["outdent", "indent"],
		["table", "horizontalRule", "link", "image", "video"],
		["fullScreen", "showBlocks", "codeView"],
		["preview", "print"],
		["removeFormat"],
	],
	defaultTag: "div",
	minHeight: "300px",
	showPathLabel: false,
	font: sortedFontOptions,
};

const MbbsDetails = () => {
	const { courseId } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [, controllerDispatch] = useSoftUIController();

	useEffect(() => {
		setMiniSidenav(controllerDispatch, true);

		return () => {
			setMiniSidenav(controllerDispatch, false);
		}
	}, [controllerDispatch]);


	const [isLoading, setIsLoading] = useState(false);
	const [collegeName, setCollegeName] = useState("");
	const [collegeId, setCollegeId] = useState("");
	const [dbId, setDbId] = useState("");
	const [cityName, setCityName] = useState("");
	const [countryName, setCountryName] = useState("");
	const [universityType, setUniversityType] = useState("");
	const [establishmentYear, setEstablishmentYear] = useState("");
	const [brochureLink, setBrochureLink] = useState("");
	const [backgroundImage, setBackgroundImage] = useState(
		"https://images.pexels.com/photos/267885/pexels-photo-267885.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
	);
	const [logoImage, setLogoImage] = useState(
		"https://static.vecteezy.com/system/resources/previews/005/260/727/original/college-graduate-icon-free-vector.jpg"
	);
	const [about, setAbout] = useState("");
	const [courseDuration, setCourseDuration] = useState();
	// const [fees, setFees] = useState();
	const [intake, setIntake] = useState([]);
	const [courseDurationFulltime, setCourseDurationFulltime] = useState();
	const [campusType, setCampusType] = useState("");
	const [campusLocation, setCampusLocation] = useState("");
	const [courseDurationInternship, setCourseDurationInternship] = useState("");
	const [courseDurationOverall, setCourseDurationOverall] = useState();
	const [applicationStartDate, setApplicationStartDate] = useState();
	const [applicationEndDate, setApplicationEndDate] = useState();
	const [teachingMedium, setTeachingMedium] = useState([]);
	const [recognisedBy, setRecognizedBy] = useState([]);
	const [videoLinks, setVideoLinks] = useState([]);
	const [facilities, setFacilities] = useState([]);
	const [firstYearFees, setFirstYearFees] = useState();
	const [totalPackage, setTotalPackage] = useState();
	const [currency, setCurrency] = useState("");
	const [syllabus, setSyllabus] = useState([{ title: "", courses: [] }]);
	const [images, setImages] = useState([{ imgCaption: "", imgLink: "" }]);
	const [videos, setVideos] = useState([]);
	const [overview, setOverview] = useState(htmlWithTable);
	const [facilitiesContent, setFacilitiesContent] = useState(htmlWithTable);
	const [admissionCriteria, setAdmissionCriteria] = useState(htmlWithTable2);
	const [feestructure, setFeeStructure] = useState(htmlWithTable3);

	const [countriesOptions, setCountriesOptions] = useState([]);
	const [currencyOptions, setCurrencyOptions] = useState([]);
	const [campusLocationOptions, setCampusLocationOptions] = useState([]);
	const [campusTypeOptions, setCampusTypeOptions] = useState([]);
	const [universityTypeOptions, setUniversityTypeOptions] = useState([]);
	const [facilitiesOptions, setFacilitiesOptions] = useState([]);
	const [teachingMediumOptions, setTeachingMediumOptions] = useState([]);
	const [recognizedByOptions, setRecognizedByOptions] = useState([]);
	const [intakeOptions, setIntakeOptions] = useState([]);
	const [ranking, setRanking] = useState([{ rankingBody: "", rank: "" }]);
	// const [faqs, setFaqs] = useState([{ query: "", answer: "" }]);
	const [otherYearFees, setOtherYearFees] = useState([]);

	const [activeIndex, setActiveIndex] = useState(0);

	const fetchDropdown = () => {
		axios
			.post(`${base_url}/data/field-options`, {
				fieldOptions: [
					{
						page: "collegeFilters",
						label: "campusType",
					},
					{
						page: "collegeFilters",
						label: "campusLocation",
					},
					{
						page: "collegeFilters",
						label: "intakeMonthYear",
					},
					{
						page: "collegeOptions",
						label: "teachingMedium",
					},
					{
						page: "mbbsOptions",
						label: "recognizedBy",
					},
					{
						page: "collegeOptions",
						label: "facilities",
					},
					{
						page: "all",
						label: "countries",
					},
					{
						page: "collegeFilters",
						label: "universityType",
					},
					{
						page: "all",
						label: "currency",
					},
				],
			})
			.then(res => {
				setCountriesOptions(res?.data?.all?.countries?.options);
				setCurrencyOptions(res?.data?.all?.currency?.options);
				setCampusLocationOptions(
					res?.data?.collegeFilters?.campusLocation?.options
				);
				setCampusTypeOptions(res?.data?.collegeFilters?.campusType?.options);
				setUniversityTypeOptions(
					res?.data?.collegeFilters?.universityType?.options
				);
				setIntakeOptions(res?.data?.collegeFilters?.intakeMonthYear?.options);
				setFacilitiesOptions(res?.data?.collegeOptions?.facilities?.options);
				setTeachingMediumOptions(
					res?.data?.collegeOptions?.teachingMedium?.options
				);
				setRecognizedByOptions(res?.data?.mbbsOptions?.recognizedBy?.options);
			});
	};

	useEffect(() => {
		fetchDropdown();
	}, []);

	const fetchData = useCallback(() => {
		if (courseId && courseId !== "create") {
			setIsLoading(true);
			axios
				.get(`${base_url}/mbbs/course/${courseId}`,)
				.then(res => {
					let data = res?.data;
					if (data) {
						data.collegeName && setCollegeName(data.collegeName);
						data.collegeId && setCollegeId(data.collegeId);
						data.dbId && setDbId(data.dbId);

						data.city && setCityName(data.city);
						data?.country && setCountryName(data?.country);

						data?.universityType && setUniversityType(data?.universityType);
						data?.campusType && setCampusType(data?.campusType);

						data.establishmentYear && setEstablishmentYear(data.establishmentYear);
						data.brochureLink && setBrochureLink(data.brochureLink);

						data.bgImg && setBackgroundImage(data.bgImg);
						data.logoImg && setLogoImage(data.logoImg);

						data.courseDurationYears && setCourseDuration(data.courseDurationYears);
						data.courseDurationYears && setCourseDurationFulltime(data.courseDurationYears * 12);
						// data.courseFees && setFees(data.courseFees);

						data.importantFacts.applicationStartDate && data.importantFacts.applicationStartDate !== 'NA' &&
							setApplicationStartDate(new Date(data.importantFacts.applicationStartDate));
						data?.intake && setIntake(data?.intake);
						data?.campusLocation && setCampusLocation(data?.campusLocation);

						data.about && setAbout(data.about);

						//Imp Facts
						data.courseDurationYears && setCourseDurationFulltime(data.courseDurationYears * 12);
						setCourseDurationInternship(
							data.importantFacts?.courseDurationInternshipMonths
						);
						setCourseDurationOverall(
							data.importantFacts?.courseDurationOverallMonths
						);
						data.importantFacts?.teachingMedium && setTeachingMedium(data.importantFacts?.teachingMedium);
						data.importantFacts?.recognisedBy && setRecognizedBy(data.importantFacts?.recognisedBy);
						setFacilities(
							data.overview?.facilitiesAndAccomodation?.facilities?.map(
								a => a.label
							)
						);
						setFacilitiesContent(data.overview?.facilitiesAndAccomodation?.content);

						data.importantFacts.applicationDeadline && data.importantFacts.applicationDeadline !== 'NA' &&
							setApplicationEndDate(new Date(data.importantFacts.applicationDeadline));
						data.overview?.benefits?.content && setOverview(data.overview?.benefits?.content);
						data.overview?.benefits?.videoLinks && setVideoLinks(data.overview?.benefits?.videoLinks);
						data.admissionCriteria && setAdmissionCriteria(data.admissionCriteria);
						data.feeStructure?.firstYearFees && setFirstYearFees(data.feeStructure?.firstYearFees);

						data.feeStructure?.currency && setCurrency(data.feeStructure?.currency);
						data.feeStructure?.totalPackage && setTotalPackage(data.feeStructure?.totalPackage);
						data.feeStructure?.content && setFeeStructure(data.feeStructure?.content);
						data.importantFacts?.ranking && setRanking(
							Object.keys(data.importantFacts?.ranking).map(key => ({
								rankingBody: key,
								rank: data.importantFacts?.ranking[key],
							}))
						);
						data?.syllabus && setSyllabus(data?.syllabus);
						// data?.faqs && setFaqs(data?.faqs);
						data?.gallery?.images && setImages(data?.gallery?.images);
						data?.gallery?.videos && setVideos(data?.gallery?.videos);
					}
					setIsLoading(false);
				})
				.catch(err => console.log(err));
		}
	}, [courseId]);

	useEffect(() => {
		fetchData();
	}, [courseId, fetchData]);

	const handleChange = (e, i) => {
		const newRanking = ranking;
		newRanking[i][e.target.name] = e.target.value;
		setRanking([...newRanking]);
	};

	const removeFormData = i => {
		const newRanking = ranking.filter((f, index) => i !== index);
		setRanking([...newRanking]);
	};

	const handleOtherFeesChange = (e, i) => {
		const newOtherFees = otherYearFees;
		newOtherFees[i][e.target.name] = e.target.value;
		setOtherYearFees([...newOtherFees]);
	};

	const removeOtherYearFeesData = i => {
		const newOtherFees = otherYearFees.filter((f, index) => i !== index);
		setOtherYearFees([...newOtherFees]);
	};

	const handleSyllabusChange = (e, i) => {
		const newSyllabus = syllabus;
		newSyllabus[i][e.target.name] = e.target.value;
		setSyllabus([...newSyllabus]);
	};

	const removeSyllabusFormData = i => {
		const newSyllabus = syllabus.filter((f, index) => i !== index);
		setSyllabus([...newSyllabus]);
	};

	const handleImageChange = (e, i) => {
		const newImages = images;
		newImages[i][e.target.name] = e.target.value;
		setImages([...newImages]);
	};

	const removeImagesFormData = i => {
		const newImages = images.filter((f, index) => i !== index);
		setImages([...newImages]);
	};

	// const handleFAQsChange = (e, i) => {
	// 	const newFAQs = faqs;
	// 	newFAQs[i][e.target.name] = e.target.value;
	// 	setFaqs([...newFAQs]);
	// };

	// const removeFromFAQs = i => {
	// 	const newFAQs = faqs.filter((f, index) => i !== index);
	// 	setFaqs([...newFAQs]);
	// };

	const saveDetails = () => {
		setIsLoading(true);

		const ranks = {};
		ranking.map((r) => ranks[r.rankingBody] = r.rank);

		const feesBreakup = {};
		otherYearFees.map((o) => feesBreakup[o.name] = o.amount);

		const startDate = !!applicationStartDate ? applicationStartDate.toString().split('GMT')[0] : null;
		const applicationStart = startDate ? new Date(startDate)?.toLocaleDateString().split('/') : null;

		const endDate = !!applicationEndDate ? applicationEndDate.toString().split('GMT')[0] : null;
		const applicationEnd = endDate ? new Date(endDate).toLocaleDateString().split('/') : null;

		const data = {
			collegeName: collegeName || null,
			city: cityName || null,
			country: countryName || null,
			courseFees: '',
			universityType: universityType || null,
			establishmentYear: establishmentYear || null,
			brochureLink: brochureLink || null,
			bgImg: backgroundImage || null,
			logoImg: logoImage || null,
			about: about || null,
			courseDurationYears: courseDuration || null,
			firstYearFees: firstYearFees || null,
			intake: intake || null,
			campusType: campusType || null,
			campusLocation: campusLocation || null,
			importantFacts: {
				applicationStartDate: `${applicationStart[2]}-${applicationStart[1]}-${applicationStart[0]}`,
				applicationDeadline: `${applicationEnd[2]}-${applicationEnd[1]}-${applicationEnd[0]}`,
				courseDurationFulltimeMonths: courseDurationFulltime || null,
				courseDurationInternshipMonths: courseDurationInternship || null,
				courseDurationOverallMonths: courseDurationOverall || null,
				recognisedBy: recognisedBy || null,
				ranking: ranks,
				teachingMedium: teachingMedium || null,
			},
			overview: {
				facilitiesAndAccomodation: {
					content: facilitiesContent || null,
					facilities: facilities || null,
				},
				benefits: {
					content: overview || null,
					videoLinks: videoLinks || null,
				}
			},
			admissionCriteria: admissionCriteria || null,
			feeStructure: {
				firstYearFees: firstYearFees || null,
				totalPackage: totalPackage || null,
				feesBreakup,
				currency: currency || null,
				content: feestructure || null,
				inrConversionMultiplier: 'null',
			},
			syllabus: syllabus || null,
			gallery: { images, videos },
			faqs: [],
		};
		if (dbId) {
			data.dbId = dbId;
			data.collegeId = collegeId;
			data.courseId = courseId;
		}

		const token = localStorage.getItem('token');

		axios
			.post(`${base_url}/mbbs/save`, data, {
				headers: {
					'accept': 'application/json',
					'token': token
				}
			})
			.then(res => {
				let data = res?.data;
				if (data?.success) {
					if (courseId && courseId !== "create") {
						// navigate(`/mbbs/${courseId}`);
					} else {
						navigate(`/mbbs-abroad`);
					}
					fetchData();
					dispatch(
						addNotification({
							message: "College saved successfully",
							type: "success",
						})
					);
				}
				setIsLoading(false);
			})
			.catch(err => console.log(err));
	};

	return (
		<>
			{isLoading ? (
				<div
					className="d-flex justify-content-center align-items-center"
					style={{ height: "100vh" }}
				>
					<Space size="middle">
						<Spin tip="Loading..." size="large" />
					</Space>
				</div>
			) : (
				<div style={{ padding: '3rem', paddingLeft: '135px' }} >
					<DashboardNavbar />
					<Button className="p-button p-button-raised p-button-text" icon="pi pi-chevron-left"
						onClick={() => navigate("/mbbs-abroad")} style={{ borderRadius: '50%', marginBottom: '20px', backgroundColor: 'white' }} />

					<TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} >
						{/* Basic */}
						<TabPanel header='Basic' style={{ marginRight: '40px' }} leftIcon="pi pi-home mr-2" >
							<Row className="mt-3">
								<Col>
									<span className="p-float-label">
										<InputText required={true} id="username" style={{ width: courseId !== "create" ? "100%" : "49%" }} value={collegeName} onChange={(e) => setCollegeName(e.target.value)} />
										<label htmlFor="username">College Name (*)</label>
									</span>
								</Col>
								{courseId !== "create" && (
									<>
										<Col>
											<span className="p-float-label">
												<InputText
													id="courseId"
													style={{ width: "100%" }}
													placeholder="Course Id"
													value={courseId}
													disabled
												/>
												<label htmlFor="courseId" className="d-block form-label">Course Id</label>
											</span>
										</Col>
										<Col>
											<span className="p-float-label">
												<InputText
													id="collegeId"
													style={{ width: "100%" }}
													placeholder="College Id"
													value={collegeId}
													disabled
												/>
												<label htmlFor="collegeId" className="d-block form-label">College Id</label>
											</span>
										</Col>
									</>
								)}
							</Row>
							<Row className="mt-3">
								<Col>
									<span className="p-float-label">
										<InputText
											style={{ width: "100%" }}
											placeholder="City"
											value={cityName}
											onChange={e => setCityName(e.target.value)}
											required={true}
										/>
										<label className="d-block form-label" style={{ fontSize: '15px' }} >City (*)</label>
									</span>
								</Col>
								<Col>
									<span className="p-float-label">
										<Dropdown
											style={{ width: "100%" }}
											value={countryName}
											onChange={e => setCountryName(e.value)}
											options={countriesOptions}
											placeholder="Country"
											required={true}
										/>
										<label className="d-block form-label">Country (*)</label>
									</span>
								</Col>
							</Row>

							<Row className="mt-3">
								<Col>
									<span className="p-float-label">
										<Dropdown
											style={{ width: "100%" }}
											value={universityType}
											onChange={e => setUniversityType(e.value)}
											options={universityTypeOptions}
											placeholder="University Type"
											required={true}
										/>
										<label className="d-block form-label">University Type (*)</label>
									</span>
								</Col>
								<Col>
									<span className="p-float-label">
										<Dropdown
											style={{ width: "100%" }}
											value={campusType}
											onChange={e => setCampusType(e.value)}
											options={campusTypeOptions}
											placeholder="Campus Type"
											required={true}
										/>
										<label className="d-block form-label">Campus Type (*)</label>
									</span>
								</Col>
							</Row>
							<Row className="mt-3">
								<Col>
									<span className="p-float-label">
										<Calendar id='establishmentYear' value={establishmentYear ? (new Date(`${establishmentYear}-01-01`)) : null} style={{ width: "100%" }}
											onChange={(e) => setEstablishmentYear(e.value.getFullYear())} view="year" dateFormat="yy" showIcon />
										<label htmlFor="establishmentYear" className="d-block form-label">Establishment Year</label>
									</span>
								</Col>
								<Col>
									<span className="p-float-label">
										<InputText
											id='brochureLink'
											style={{ width: "100%" }}
											placeholder="Brochure Link"
											value={brochureLink}
											onChange={e => setBrochureLink(e.target.value)}
										/>
										<label htmlFor="brochureLink" className="d-block form-label">Brochure Link</label>
									</span>
								</Col>
							</Row>
							<Row className="mt-3">
								<Col>
									<span className="p-float-label">
										<InputText
											id='backgroundImage'
											style={{ width: "100%" }}
											placeholder="Background Image"
											value={backgroundImage}
											onChange={e => setBackgroundImage(e.target.value)}
										/>
										<label id='backgroundImage' className="d-block form-label">Background Image</label>
									</span>
								</Col>
								<Col>
									<span className="p-float-label">
										<InputText
											id="logoImage"
											style={{ width: "100%" }}
											placeholder="Logo Image"
											value={logoImage}
											onChange={e => setLogoImage(e.target.value)}
										/>
										<label htmlFor="logoImage" className="d-block form-label">Logo Image</label>
									</span>
								</Col>
							</Row>

							<Row className="mt-3">
								<Col>
									<span className="p-float-label" >
										<InputNumber
											inputId='courseDuration'
											style={{ width: "100%" }}
											placeholder="Course Duration (eg. 5, 5.5, 6 years)"
											value={courseDuration}
											minFractionDigits={1}
											maxFractionDigits={1}
											onChange={e => {
												const val = Math.round(e.value * 2) / 2;
												setCourseDuration(val);
												setCourseDurationFulltime(val * 12);
												setCourseDurationOverall(
													(val * 12) + +courseDurationInternship
												);
											}}
										/>
										<label htmlFor="courseDuration" className="d-block form-label">
											Course Duration (eg. 5, 5.5, 6.5 years)
										</label>
									</span>
								</Col>
								<Col>
									<span className="p-float-label" >
										<Dropdown
											id='campusLocation'
											style={{ width: "100%" }}
											value={campusLocation}
											onChange={e => setCampusLocation(e.value)}
											options={campusLocationOptions}
											placeholder="Campus Location"
										/>
										<label htmlFor="campusLocation" className="d-block form-label">Campus Location</label>
									</span>
									{/* <span className="p-float-label" >
										<InputNumber
											inputId='fees'
											style={{ width: "100%" }}
											placeholder="Fees"
											value={fees}
											onValueChange={e => setFees(e.value)}
											mode="currency" currency="USD" currencyDisplay="code" locale="en-IN"
										/>
										<label htmlFor="fees" className="d-block form-label">Fees</label>
									</span> */}
								</Col>
							</Row>
							<Row className="mt-3">
								<Col>
									<span className="p-float-label" >
										<Calendar
											id="applicationStartDate"
											style={{ width: "100%" }}
											placeholder="Application Start Date"
											value={applicationStartDate}
											onChange={e => setApplicationStartDate(new Date(e.target.value))}
											showButtonBar showIcon
										/>
										<label htmlFor="applicationStartDate" className="d-block form-label">
											Application Start Date
										</label>
									</span>
								</Col>
								<Col>
									<span className="p-float-label" >
										<MultiSelect
											id='intakeOptions'
											value={intake}
											style={{ width: "100%" }}
											onChange={e => setIntake(e.value)}
											options={intakeOptions}
											placeholder="Intake"
											optionLabel="label" display="chip"
										/>
										<label htmlFor="intakeOptions" className="d-block form-label">Intake</label>
									</span>
								</Col>
							</Row>
							<Row className="mt-3" >
								<Col>
									<span className="p-float-label">
										<InputTextarea
											id='about'
											autoResize
											style={{ width: "100%" }}
											placeholder="About"
											value={about}
											onChange={e => setAbout(e.target.value)}
										/>
										<label htmlFor="about" className="d-block form-label">About</label>
									</span>
								</Col>
							</Row>

							<Row className="mt-3" >
								<div>
									<Button className="float-end" style={{ width: '150px' }}
										label='Next' icon='pi pi-caret-right' iconPos="right"
										onClick={() => setActiveIndex((prev) => prev + 1)}
										disabled={!collegeName || !cityName || !countryName || !universityType || !campusType} />
								</div>
							</Row>
						</TabPanel>

						{/* Important Facts */}
						<TabPanel header='Important Facts' style={{ marginRight: '40px' }} leftIcon='pi pi-server mr-2' >
							<Row className="mt-3">
								<label className="d-block form-label">Course Duration</label>
								<Col>
									<span className="p-float-label">
										<InputNumber
											inputId='courseDurationFulltime'
											style={{ width: "100%" }}
											placeholder="Fulltime (months)"
											value={courseDurationFulltime}
											disabled
										/>
										<label htmlFor="courseDurationFulltime" className="d-block form-label">
											Fulltime (months)
										</label>
									</span>
								</Col>
								<Col>
									<span className="p-float-label">
										<InputNumber
											inputId='courseDurationInternship'
											style={{ width: "100%" }}
											placeholder="Internship (months)"
											value={courseDurationInternship}
											onValueChange={e => {
												setCourseDurationOverall(
													+courseDurationFulltime + +e.value
												);
												return setCourseDurationInternship(e.value);
											}}
										/>
										<label htmlFor="courseDurationInternship" className="d-block form-label">
											Internship (months)
										</label>
									</span>
								</Col>
								<Col>
									<span className="p-float-label">
										<InputNumber
											inputId="courseDurationOverall"
											style={{ width: "100%" }}
											placeholder="Overall (months)"
											value={courseDurationOverall}
											disabled
										/>
										<label htmlFor="courseDurationOverall" className="d-block form-label">
											Overall (months)
										</label>
									</span>
								</Col>
							</Row>

							<Row className="mt-3" >
								<Col>
									<span className="p-float-label">
										<Calendar
											inputId='applicationEndDate'
											style={{ width: "100%" }}
											placeholder="Application End Date"
											value={applicationEndDate}
											onChange={e => setApplicationEndDate(new Date(e.target.value))}
											showButtonBar showIcon
										/>
										<label htmlFor="applicationEndDate" className="d-block form-label">Application End Date</label>
									</span>
								</Col>
								<Col>
									<span className="p-float-label">
										<MultiSelect
											inputId="teachingMedium"
											value={teachingMedium}
											style={{ width: "100%" }}
											onChange={e => setTeachingMedium(e.value)}
											options={teachingMediumOptions}
											placeholder="Teaching Medium"
											optionLabel="label" display="chip"
										/>
										<label htmlFor="teachingMedium" className="d-block form-label">Teaching Medium</label>
									</span>
								</Col>
							</Row>
							<Row className="mt-3" >
								<Col>
									<label className="d-block form-label">Recognized By</label>
									<MultiSelect
										value={recognisedBy}
										style={{ width: "100%" }}
										onChange={e => setRecognizedBy(e.value)}
										options={recognizedByOptions}
										placeholder="Recognized By"
										optionLabel="label" display="chip"
									/>
								</Col>
							</Row>
							<Button
								onClick={() =>
									setRanking([...ranking, { rankingBody: "", rank: "" }])
								}
								label='Add Rank'
								style={{ backgroundColor: '#0d6efd', color: 'white', height: '40px' }}
								icon='pi pi-plus' iconPos="right"
							/>
							<form>
								{ranking?.map((body, index) => {
									return (
										<Row key={index} className="w-50">
											<Col>
												<InputText
													style={{ width: "100%" }}
													placeholder="Ranking Body"
													name="rankingBody"
													value={body?.rankingBody}
													onChange={e => handleChange(e, index)}
												/>
											</Col>
											<Col>
												<InputText
													style={{ width: "100%" }}
													placeholder="Rank"
													name="rank"
													value={body?.rank}
													onChange={e => handleChange(e, index)}
												/>
											</Col>
											<Col>
												<Button
													onClick={() => removeFormData(index)}
													label='Remove'
													style={{ backgroundColor: 'red', color: 'white' }}
													icon='pi pi-times' iconPos="right"
												/>
											</Col>
										</Row>
									);
								})}
							</form>

							<Row className="mt-3" >
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<Button style={{ width: '150px' }}
										label='Previous' icon='pi pi-caret-left'
										onClick={() => setActiveIndex((prev) => prev - 1)} />

									<Button style={{ width: '150px' }}
										label='Next' icon='pi pi-caret-right' iconPos="right"
										onClick={() => setActiveIndex((prev) => prev + 1)} />
								</div>
							</Row>
						</TabPanel>

						{/* Overview */}
						<TabPanel header='Overview' style={{ marginRight: '40px' }} leftIcon='pi pi-bars mr-2'>
							<Row className="mt-2">
								<label className="d-block form-label">Benefits</label>
								<SunEditor
									plugin=""
									setContents={overview}
									onChange={setOverview}
									setOptions={editorOptions}
								/>
							</Row>
							<Row className="mt-5" >
								<Col>
									<span className="p-float-label">
										<Chips
											id='videoLinks'
											placeholder="Video Links"
											style={{ display: "block" }}
											value={videoLinks}
											onChange={e => setVideoLinks(e.value)}
											separator=","
										/>
										<label htmlFor="videoLinks" className="d-block form-label">Video Links</label>
										<small>
											Links separated by Comma(,) or Enter
										</small>
									</span>
								</Col>
								<Col>
									<span className="p-float-label">
										<MultiSelect
											inputId="facilities" id='facilities'
											value={facilities}
											style={{ width: "100%" }}
											onChange={e => setFacilities(e.value)}
											options={facilitiesOptions}
											placeholder="Facilities"
											optionLabel="label" display="chip"

										/>
										<label htmlFor="facilities" className="d-block form-label">Facilities</label>
									</span>
								</Col>
							</Row>

							<Row className="mt-3">
								<label className="d-block form-label">Facilities</label>
								<SunEditor
									plugin=""
									setContents={facilitiesContent}
									onChange={setFacilitiesContent}
									setOptions={editorOptions}
								/>
							</Row>

							<Row className="mt-3" >
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<Button style={{ width: '150px' }}
										label='Previous' icon='pi pi-caret-left'
										onClick={() => setActiveIndex((prev) => prev - 1)} />

									<Button style={{ width: '150px' }}
										label='Next' icon='pi pi-caret-right' iconPos="right"
										onClick={() => setActiveIndex((prev) => prev + 1)} />
								</div>
							</Row>
						</TabPanel>

						{/* Admission Criteria */}
						<TabPanel header='Admission Criteria' style={{ marginRight: '40px' }} leftIcon='pi pi-check-square mr-2' >
							<Row className="mt-2">
								<label className="d-block form-label" >Admission Criteria</label>
								<SunEditor
									plugin=""
									setContents={admissionCriteria}
									onChange={setAdmissionCriteria}
									setOptions={editorOptions}
								/>
							</Row>

							<Row className="mt-3" >
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<Button style={{ width: '150px' }}
										label='Previous' icon='pi pi-caret-left'
										onClick={() => setActiveIndex((prev) => prev - 1)} />

									<Button style={{ width: '150px' }}
										label='Next' icon='pi pi-caret-right' iconPos="right"
										onClick={() => setActiveIndex((prev) => prev + 1)} />
								</div>
							</Row>
						</TabPanel>

						{/* Fee Structure */}
						<TabPanel header='Fee Structure' style={{ marginRight: '40px' }} leftIcon='pi pi-dollar mr-2' >
							<Row className="mt-3">
								<Col>
									<span className="p-float-label">
										<InputNumber
											inputId="firstYearFees"
											style={{ width: "100%" }}
											placeholder="First Year Fees"
											value={firstYearFees}
											onValueChange={e => setFirstYearFees(e.value)}
										/>
										<label htmlFor="firstYearFees" className="d-block form-label">First Year Fees</label>
									</span>
								</Col>
								<Col>
									<span className="p-float-label">
										<InputNumber
											inputId="totalPackage"
											style={{ width: "100%" }}
											placeholder="Total Package"
											value={totalPackage}
											onValueChange={e => setTotalPackage(e.value)}
										/>
										<label htmlFor="totalPackage" className="d-block form-label">Total Package</label>
									</span>
								</Col>
								<Col>
									<span className="p-float-label">
										<Dropdown
											id='currency'
											style={{ width: "100%" }}
											value={currency}
											onChange={e => setCurrency(e.value)}
											options={currencyOptions}
											placeholder="Currency"
										/>
										<label htmlFor="currency" className="d-block form-label">Currency</label>
									</span>
								</Col>
							</Row>
							<Row className="mt-3" >
								<div>
									<Button
										onClick={() =>
											setOtherYearFees([...otherYearFees, { name: "", amount: 0 }])
										}
										label='Add Fees'
										style={{ backgroundColor: '#0d6efd', color: 'white', height: '40px' }}
										icon='pi pi-plus' iconPos="right"
									/>
								</div>
								<form>
									{otherYearFees?.map((body, index) => {
										return (
											<Row key={index} className="w-50">
												<Col>
													<InputText
														style={{ width: "100%" }}
														placeholder="Second Year"
														name="name"
														value={body?.name}
														onChange={e => handleOtherFeesChange(e, index)}
													/>
												</Col>
												<Col>
													<InputNumber
														style={{ width: "100%" }}
														placeholder="Amount"
														name="amount"
														value={body?.amount}
														onChange={e => handleOtherFeesChange(e, index)}
													/>
												</Col>
												<Col>
													<Button
														onClick={() => removeOtherYearFeesData(index)}
														label='Remove'
														style={{ backgroundColor: 'red', color: 'white' }}
														icon='pi pi-times' iconPos="right"
													/>
												</Col>
											</Row>
										);
									})}
								</form>

							</Row>
							<Row className="mt-3" >
								<SunEditor
									plugin=""
									setContents={feestructure}
									onChange={setFeeStructure}
									setOptions={editorOptions}
								/>
							</Row>

							<Row className="mt-3" >
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<Button style={{ width: '150px' }}
										label='Previous' icon='pi pi-caret-left'
										onClick={() => setActiveIndex((prev) => prev - 1)} />

									<Button style={{ width: '150px' }}
										label='Next' icon='pi pi-caret-right' iconPos="right"
										onClick={() => setActiveIndex((prev) => prev + 1)} />
								</div>
							</Row>
						</TabPanel>

						{/* Syllabus */}
						<TabPanel header='Syllabus' style={{ marginRight: '40px' }} leftIcon="pi pi-book mr-2" >
							<Row className="mt-3">
								<div style={{ display: 'flex', justifyContent: 'flex-end' }} >
									<Button
										onClick={() =>
											setSyllabus([...syllabus, { title: "", courses: "" }])
										}
										label='Add Syllabus'
										style={{ backgroundColor: '#0d6efd', color: 'white', height: '40px' }}
										icon='pi pi-plus' iconPos="right"
									/>
								</div>

								<form >
									{syllabus?.map((body, index) => {
										return (
											<Row key={index} className="mt-4">
												<Col>
													<span className="p-float-label">
														<InputText
															id={`title-${index}`}
															style={{ width: "100%" }}
															placeholder="Title"
															name="title"
															value={body?.title}
															onChange={e => handleSyllabusChange(e, index)}
														/>
														<label htmlFor={`title-${index}`} className="d-block form-label">Title</label>
													</span>
												</Col>
												<Col>
													<span className="p-float-label">
														<Chips
															id={`courses-${index}`}
															name="courses"
															placeholder="Courses"
															style={{ display: "block" }}
															value={body?.courses}
															onChange={e => handleSyllabusChange(e, index)}
															separator=','
														/>
														<label htmlFor={`courses-${index}`} className="d-block form-label">Courses</label>
														<small>
															Courses separated by Comma(,) or Enter
														</small>
													</span>
												</Col>
												<Col>
													{index !== 0 && <Button
														onClick={() => removeSyllabusFormData(index)}
														label='Remove'
														style={{ backgroundColor: 'red', color: 'white' }}
														icon='pi pi-times' iconPos="right"
													/>}
												</Col>
											</Row>
										);
									})}
								</form>
							</Row>

							<Row className="mt-3" >
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<Button style={{ width: '150px' }}
										label='Previous' icon='pi pi-caret-left'
										onClick={() => setActiveIndex((prev) => prev - 1)} />

									<Button style={{ width: '150px' }}
										label='Next' icon='pi pi-caret-right' iconPos="right"
										onClick={() => setActiveIndex((prev) => prev + 1)} />
								</div>
							</Row>
						</TabPanel>

						{/* FAQs */}
						{/* <TabPanel header='FAQs' style={{ marginRight: '20px' }} leftIcon="pi pi-question-circle mr-2" >
							<Row className="mt-3">
								<div style={{ display: 'flex', justifyContent: 'flex-end' }} >
									<Button
										onClick={() =>
											setFaqs([...faqs, { query: "", answer: "" }])
										}
										label='Add Q/A'
										style={{ backgroundColor: '#0d6efd', color: 'white', height: '40px' }}
										icon='pi pi-plus' iconPos="right"
									/>
								</div>

								<form >
									{faqs?.map((body, index) => {
										return (
											<Row key={index} className="mt-4">
												<Col>
													<span className="p-float-label">
														<InputText
															id={`query-${index}`}
															style={{ width: "100%" }}
															placeholder="Query"
															name="query"
															value={body?.query}
															onChange={e => handleFAQsChange(e, index)}
														/>
														<label htmlFor={`query-${index}`} className="d-block form-label">Query</label>
													</span>
												</Col>
												<Col>
													<span className="p-float-label">
														<InputText
															id={`answer-${index}`}
															style={{ width: "100%" }}
															name="answer"
															placeholder="Answer"
															value={body?.answer}
															onChange={e => handleFAQsChange(e, index)}
														/>
														<label htmlFor={`answer-${index}`} className="d-block form-label">Answer</label>
													</span>
												</Col>
												<Col>
													{index !== 0 && <Button
														onClick={() => removeFromFAQs(index)}
														label='Remove'
														style={{ backgroundColor: 'red', color: 'white' }}
														icon='pi pi-times' iconPos="right"
													/>}
												</Col>
											</Row>
										);
									})}
								</form>
							</Row>

							<Row className="mt-3" >
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<Button style={{ width: '150px' }}
										label='Previous' icon='pi pi-caret-left'
										onClick={() => setActiveIndex((prev) => prev - 1)} />

									<Button style={{ width: '150px' }}
										label='Next' icon='pi pi-caret-right' iconPos="right"
										onClick={() => setActiveIndex((prev) => prev + 1)} />
								</div>
							</Row>
						</TabPanel> */}

						{/* Gallery */}
						<TabPanel header='Gallery' leftIcon="pi pi-camera mr-2" >
							<Row className="mt-3">
								<div className="mt-2" style={{ display: 'flex', justifyContent: 'flex-end' }} >
									<Button
										onClick={() =>
											setImages([...images, { imgCaption: "", imgLink: "" }])
										}
										label='Add Images'
										style={{ backgroundColor: '#0d6efd', color: 'white', height: '40px' }}
										icon='pi pi-plus' iconPos="right"
									/>

								</div>

								<form>
									{images?.map((body, index) => {
										return (
											<Row key={index} className="mt-3">
												<Col>
													<InputText
														style={{ width: "100%" }}
														placeholder="Image Caption"
														name="imgCaption"
														value={body?.imgCaption}
														onChange={e => handleImageChange(e, index)}
													/>
												</Col>
												<Col>
													<InputText
														style={{ width: "100%" }}
														placeholder="Image Link"
														name="imgLink"
														value={body?.imgLink}
														onChange={e => handleImageChange(e, index)}
													/>
												</Col>
												<Col>
													<Button
														onClick={() => removeImagesFormData(index)}
														label='Remove'
														style={{ backgroundColor: 'red', color: 'white' }}
														icon='pi pi-times' iconPos="right"
													/>
												</Col>
											</Row>
										);
									})}
								</form>
							</Row>
							<Row>
								<Col>
									<span className="p-float-label">
										<Chips
											id="videoLinks"
											placeholder="Add Video Links"
											style={{ display: "block" }}
											value={videos}
											onChange={e => setVideos(e.value)}
										/>
										<label htmlFor="videoLinks" className="d-block form-label">Videos</label>
										<small>
											Links separated by Comma(,) or Enter
										</small>
									</span>
								</Col>
							</Row>

							<Row className="mt-3" >
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<Button style={{ width: '150px' }}
										label='Previous' icon='pi pi-caret-left'
										onClick={() => setActiveIndex((prev) => prev - 1)} />

									<Button style={{ width: '160px' }}
										label='Save Details' icon='pi pi-save' iconPos="right"
										onClick={() => saveDetails()} />
								</div>
							</Row>
						</TabPanel>
					</TabView>


				</div>
			)}
		</>
	);
};

export default MbbsDetails;
