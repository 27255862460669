import MUICard from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import SoftBox from "../../components/SoftBox";
import SoftAvatar from "../../components/SoftAvatar";
import SoftTypography from "../../components/SoftTypography";

const Details = ({ title, value }) => (
  <SoftBox key={title} display="flex" py={1} pr={2}>
    <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
      {title}: &nbsp;
    </SoftTypography>
    <SoftTypography variant="button" fontWeight="regular" color="text">
      &nbsp;{value}
    </SoftTypography>
  </SoftBox>
);

const Card = ({ image, title, collegeName, otherDetails, status, onClick }) => {
  return (
    <MUICard sx={{ height: "100%" }} onClick={onClick}
      style={{ borderLeft: `4px solid ${status}`, cursor: 'pointer' }} >
      <SoftBox display="flex" alignItems="center" pt={2} px={2}>
        <Grid item>
          <SoftAvatar
            src={image}
            alt="profile-image"
            variant="rounded"
            size="l"
            shadow="sm"
          />
        </Grid>
        <Grid item>
          <SoftBox height="100%" ml={2} mt={0.5} lineHeight={1}>
            <SoftTypography variant="h5" color='info' fontWeight="medium">
              {title}
            </SoftTypography>
            <SoftTypography variant="button" color="text" fontWeight="medium">
              {collegeName}
            </SoftTypography>
          </SoftBox>
        </Grid>
      </SoftBox>
      <SoftBox p={2}>
        <SoftBox>
          {otherDetails.map(({ title, value }) => <Details title={title} value={value} />)}
        </SoftBox>
      </SoftBox>
    </MUICard>
  )
}
export default Card